<template>
  <div class="container-fluid">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div class="row">
          <div class="col my-auto">
            <h1 class="fw-bold text-uppercase text-primary">
              Marketing Send-outs
            </h1>
          </div>
          <div class="col-auto ms-auto my-auto">
            <button
              class="btn btn-outline-primary btn-sm"
              @click="$router.push('/marketing-suite')"
            >
              <i class="far fa-arrow-left me-2"></i>Marketing
            </button>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-8 my-auto">
            <p class="text-dark">
              From here you can manage your Marketing Send-outs, choose a
              marketing list, an email template, set a date &amp; time and
              schedule your email to be sent.
            </p>
          </div>
          <div class="col-auto ms-auto my-auto">
            <router-link
              to="/marketing-sendouts/create"
              class="btn btn-primary"
            >
              <i class="far fa-plus me-2"></i>Create Marketing Send-out
            </router-link>
          </div>
        </div>

        <div class="card border-0 shadow-none bg-light">
          <div class="card-body p-4">
            <busy v-if="loading" />
            <div v-else>
              <ul
                class="list-group list-group-flush"
                v-if="sendouts.length > 0"
              >
                <li
                  class="list-group-item mb-3 shadow-sm border-0 p-3"
                  v-for="l in sendouts"
                  :key="l.id"
                >
                  <div class="row">
                    <div class="col my-auto">
                      <p class="mb-0 fw-bold text-primary">{{ l.name }}</p>
                    </div>
                    <div class="col-auto my-auto">
                      <p class="mb-0">
                        <i>Scheduled to send on {{ l.send_at | formatDate }}</i>
                      </p>
                    </div>
                    <div class="col-auto my-auto">
                      <button
                        @click="$router.push('/marketing-sendouts/' + l.id)"
                        class="btn btn-outline-primary btn-sm"
                      >
                        <i class="far fa-eye me-2"></i>Manage
                      </button>
                    </div>
                  </div>
                </li>
              </ul>

              <div
                v-else
                class="d-flex justify-content-center align-items-center"
              >
                <div class="text-center">
                  <div>
                    <i class="far fa-search fa-5x text-primary"></i>
                  </div>
                  <p class="fw-bold mt-4">You have no marketing sendouts yet</p>
                  <p class="fw-bold mt-2">Add one above</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from "@/components/Busy";
export default {
  components: { Busy },
  data() {
    return {
      loading: true,
      sendouts: [],
      form: {
        name: "",
      },
    };
  },
  methods: {
    async addSendout() {
      const { data } = await this.$axios.post(process.env.VUE_APP_API_URL +"/marketing-sendouts", this.form);
      this.sendouts.push(data.list);
      this.form = {
        name: "",
      };
      this.$EventBus.$emit("alert", { message: data.message });
    },
    async fetchSendouts() {
      const { data } = await this.$axios.get(process.env.VUE_APP_API_URL +"/marketing-sendouts");
      this.sendouts = data;
      this.loading = false;
    },
  },
  created() {
    this.fetchSendouts();
  },
};
</script>

<style>
</style>
